import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { graphql } from "gatsby";
import { MessagesBasic } from "../../examples/components/Messages";
export const query = graphql`
  query MessageQuery {
    Message: componentMetadata(displayName: { eq: "Message" }) {
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Overview = makeShortcode("Overview");
const CodeBlock = makeShortcode("CodeBlock");
const LinkedHeading = makeShortcode("LinkedHeading");
const ComponentApi = makeShortcode("ComponentApi");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Overview mdxType="Overview">
  Messages should be used within other elements to convey helpful information in
  an unobtrusive way.
    </Overview>
    <CodeBlock title="Examples" code={MessagesBasic} mdxType="CodeBlock">
  Use <code>&lt;Message&gt;</code> component with a <code>variant</code> to
  apply color and <code>message</code> prop for displaying text. Pass modus icon
  name to the prop <code>icon</code> to display within a message to further
  convey meaning.
    </CodeBlock>
    <LinkedHeading h="2" className="h1" id="messagr-api" mdxType="LinkedHeading">
  API
    </LinkedHeading>
    <ComponentApi metadata={props.data.Message} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      